import React, { useContext } from "react";
import { Context, RichText } from "../..";

export const getContactCompanyNameFull = () => {
    return `${getContactCompanyNamePrefix()} ${getContactCompanyNameSuffix()}`;
};
export const getContactCompanyNameShort = () => {
    return `${getContactCompanyNamePrefix()}`;
};

export const getContactCompanyNamePrefix = () => {
    const c = useContext(Context);
    if (!c?.CompanyInformationPublic) return "";
    const { companyNameOverwriteContact, companyName } = c?.CompanyInformationPublic;
    return companyNameOverwriteContact || companyName || "";
};

export const getContactCompanyNameSuffix = () => {
    const c = useContext(Context);
    if (!c?.CompanyInformationPublic) return "";
    const { companyFormOfLaw, companyNameOverwriteContact } = c?.CompanyInformationPublic;
    return companyNameOverwriteContact ? "" : " " + getCompanyFormFormattedForContact(companyFormOfLaw);
};

// LTD
// AG
// OG
// VEREIN
// mbH
// SL
// EINZELUNTERNEHMER
export const getCompanyFormFormattedForContact = (companyFormOfLaw) => {
    if (companyFormOfLaw == "GBR" || companyFormOfLaw == "KGT" || companyFormOfLaw == "EINZELKAUFMANN") {
        return "";
    }
    return getCompanyFormFormatted(companyFormOfLaw);
};

export const getCompanyFormFormatted = (companyFormOfLaw) => {
    return companyFormOfLaw == "GMBHCOKG"
        ? `GmbH & Co. KG`
        : companyFormOfLaw == "GMBH"
          ? `GmbH`
          : companyFormOfLaw == "UG"
            ? `UG (haftungsbeschränkt)`
            : companyFormOfLaw == "EINZELKAUFMANN"
              ? `e.K.`
              : companyFormOfLaw == "EINZELUNTERNEHMER"
                ? `Einzelunternehmer`
                : companyFormOfLaw == "GBR"
                  ? `GbR`
                  : companyFormOfLaw;
};

export const getLeadingCharacter = (lawString: string) => {
    switch (true) {
        case lawString == "e.k.":
            return "Inhaber";
        case lawString.includes("einzel"):
            return "Inhaber";
        case lawString == "mbH":
            return "Geschäftsführer";
        case lawString == "ug":
            return "Geschäftsführer";
        case lawString.includes("gmbh"):
            return "Geschäftsführer";
        case lawString == "gbr":
            return "Gesellschafter";
        case lawString == "ohg":
            return "Gesellschafter";
        case lawString == "ag":
            return "Gesellschafter";
        case lawString == "kg":
            return "Gesellschafter";
        default:
            return "Geschäftsführer";
    }
};

export const ImprintAppendum = ({ imprintAddendum }) => {
    if (imprintAddendum && imprintAddendum.content) {
        return (
            <>
                <br></br>
                <RichText textContent={imprintAddendum} fragment></RichText>
                <br></br>
            </>
        );
    }
    return null;
};

export const ImprintCompanyName = () => {
    const c = useContext(Context);

    if (!c?.CompanyInformationPublic) {
        return null;
    }

    let { companyFormOfLaw } = c?.CompanyInformationPublic;
    const { companyName: cN, companyNameOverwriteImprint, managingDirector } = c?.CompanyInformationPublic;

    const companyName = companyNameOverwriteImprint || cN;

    if (!companyFormOfLaw) {
        companyFormOfLaw = "GBR" as any;
    }

    if (!c?.slug || c?.PageImprint?.slug !== c?.slug) {
        return null;
    }

    const lawString = `${companyFormOfLaw}`.toLocaleLowerCase().trim();

    const companyFormOfLawFormatted = getCompanyFormFormatted(companyFormOfLaw);

    if (
        lawString.includes("gmbh") ||
        lawString.includes("ohg") ||
        lawString.includes("kg") ||
        lawString.includes("ag") ||
        lawString.includes("ug")
    ) {
        return (
            <>
                {companyName ?? ""}
                {` ${companyFormOfLawFormatted}`}
            </>
        );
    }

    // e.K
    if (lawString.includes("einzelkaufmann")) {
        if (companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "") {
            return (
                <>
                    {companyName ?? ""}
                    {", Inh. " + managingDirector}
                </>
            );
        }
        return (
            <>
                {companyName ?? ""}
                {", Inh. " + managingDirector + ", "}
                {companyFormOfLawFormatted}
            </>
        );
    }

    if (lawString.includes("einzel")) {
        return (
            <>
                {companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "" ? (
                    <>{companyNameOverwriteImprint}</>
                ) : (
                    <>
                        {managingDirector ?? ""} {"(" + companyFormOfLawFormatted + ")"}{" "}
                    </>
                )}
            </>
        );
    }

    // GBR
    return (
        <>
            {companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "" ? (
                <>{companyNameOverwriteImprint}</>
            ) : (
                <>{managingDirector + " " + companyFormOfLawFormatted ?? ""}</>
            )}
        </>
    );
};

export const ImprintStart = () => {
    const c = useContext(Context);

    if (c?.CompanyInformationPublic) {
        let { companyFormOfLaw } = c?.CompanyInformationPublic;
        const {
            companyName: cN,
            companyNameOverwriteImprint,
            street,
            place,
            taxID,
            // country: fullcountry,
            country,
            postcode,
            commercialRegister,
            managingDirector,
            phone,
            fax,
            email,
            imprintAddendum,
            persHaftendeGesName,
            persHaftendeGesSitz,
            persHaftendeGesHandelsregister,
        } = c?.CompanyInformationPublic;

        const companyName = companyNameOverwriteImprint || cN;

        // const country = fullcountry ? `${fullcountry}`.toUpperCase().trim()[0] : fullcountry;

        if (!companyFormOfLaw) {
            companyFormOfLaw = "GBR" as any;
        }

        if (!c?.slug || c?.PageImprint?.slug !== c?.slug) {
            return null;
        }

        const lawString = `${companyFormOfLaw}`.toLocaleLowerCase().trim();

        const companyFormOfLawFormatted = getCompanyFormFormatted(companyFormOfLaw);

        const leadingCharacter = getLeadingCharacter(lawString);

        if (lawString == "gmbhcokg" && persHaftendeGesHandelsregister && persHaftendeGesName && persHaftendeGesSitz) {
            // console.log(` lawString`, lawString);

            return (
                <>
                    <p>
                        {companyName ?? ""}
                        {` ${companyFormOfLawFormatted}`}
                        <br />
                        {street ?? ""}
                        <br />
                        {country ?? ""} {postcode ?? ""} {place ?? ""}
                        <br />
                    </p>
                    <p>
                        vertreten durch die persönlich haftende Gesellschafterin:
                        <br />
                        {`${persHaftendeGesName},`}
                        <br />
                        diese vertreten durch den/die Geschäftsführer
                        <br />
                        <span> {managingDirector ? " " + managingDirector : ""}</span>
                        <br />
                        Sitz: {persHaftendeGesSitz ?? ""}
                        <br />
                        Handelsregister: {persHaftendeGesHandelsregister ?? ""}
                        <br />
                    </p>
                    <p>
                        {phone && (
                            <>
                                Telefon: {phone}
                                <br />
                            </>
                        )}
                        {fax && (
                            <>
                                Fax: {fax}
                                <br />
                            </>
                        )}
                        {email && (
                            <p>
                                E-Mail: <a href={`mailto:${email}`}>{email}</a>
                            </p>
                        )}
                        {typeof window != "undefined" ? (
                            <a href={`https://${window.location.hostname}`} target="_blank">
                                {window.location.hostname}
                            </a>
                        ) : (
                            ""
                        )}
                    </p>
                    USt ID: {taxID ?? ""}
                    <br></br>
                    {commercialRegister && `Handelsregister: ${commercialRegister}`}
                    <br></br>
                    <br></br>
                    Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:<br></br>{" "}
                    <span> {managingDirector ? " " + managingDirector : ""}</span>
                    <br></br>
                    <ImprintAppendum imprintAddendum={imprintAddendum} />
                </>
            );
        }

        if (
            lawString.includes("gmbh") ||
            lawString.includes("ohg") ||
            lawString.includes("kg") ||
            lawString.includes("ag") ||
            lawString.includes("ug")
        ) {
            return (
                <>
                    <p>
                        {companyName ?? ""}
                        {` ${companyFormOfLawFormatted}`}
                        <br />
                        {street ?? ""}
                        <br />
                        {country ?? ""} {postcode ?? ""} {place ?? ""}
                        <br />
                    </p>
                    <p>
                        Vertretungsberechtigt: <br />
                        Die <span>{`${companyFormOfLawFormatted}`}</span> wird durch{" "}
                        {leadingCharacter == "Inhaber" ? "den" : lawString == "gbr" ? "die" : "den/die"}
                        {" " + leadingCharacter}
                        <br />
                        <span>{managingDirector ?? ""} vertreten.</span>
                    </p>
                    <p>
                        {phone && (
                            <>
                                Telefon: {phone}
                                <br />
                            </>
                        )}
                        {fax && (
                            <>
                                Fax: {fax}
                                <br />
                            </>
                        )}
                        {email && (
                            <p>
                                E-Mail: <a href={`mailto:${email}`}>{email}</a>
                            </p>
                        )}
                        {typeof window != "undefined" ? (
                            <a href={`https://${window.location.hostname}`} target="_blank">
                                {window.location.hostname}
                            </a>
                        ) : (
                            ""
                        )}
                    </p>
                    USt ID: {taxID ?? ""}
                    <br></br>
                    {commercialRegister && `Handelsregister: ${commercialRegister}`}
                    <br></br>
                    <br></br>
                    Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:<br></br>{" "}
                    <span> {managingDirector ? " " + managingDirector : ""}</span>
                    <br></br>
                    <ImprintAppendum imprintAddendum={imprintAddendum} />
                </>
            );
        }

        // e.K
        if (lawString.includes("einzelkaufmann")) {
            const isOverwrite = companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "";
            return (
                <>
                    <p>
                        {companyName ?? ""}
                        {", Inh. " + managingDirector + (isOverwrite ? "" : ", ")}
                        {isOverwrite ? null : companyFormOfLawFormatted}
                        <br />
                        {street ?? ""} <br />
                        {country ?? ""} {postcode ?? ""} {place ?? ""}
                        <br />
                    </p>
                    <p>
                        Vertretungsberechtigt: <br />
                        <span>{managingDirector ?? ""}</span>
                    </p>
                    <p>
                        {phone && (
                            <>
                                Telefon: {phone}
                                <br />
                            </>
                        )}
                        {fax && (
                            <>
                                Fax: {fax}
                                <br />
                            </>
                        )}
                        {email && (
                            <p>
                                E-Mail: <a href={`mailto:${email}`}>{email}</a>
                            </p>
                        )}
                        {typeof window != "undefined" ? (
                            <a href={`https://${window.location.hostname}`} target="_blank">
                                {window.location.hostname}
                            </a>
                        ) : (
                            ""
                        )}
                    </p>
                    USt ID: {taxID ?? ""}
                    <br></br>
                    {commercialRegister && `Handelsregister: ${commercialRegister}`}
                    <br></br>
                    <br></br>
                    Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:<br></br>
                    {managingDirector ?? ""}
                    <br></br>
                    <ImprintAppendum imprintAddendum={imprintAddendum} />
                </>
            );
        }

        if (lawString.includes("einzel")) {
            return (
                <>
                    <p>
                        {companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "" ? (
                            <>
                                {companyNameOverwriteImprint} <br />
                            </>
                        ) : (
                            <>
                                {managingDirector ?? ""} {"(" + companyFormOfLawFormatted + ")"} <br />
                            </>
                        )}
                        <br />
                        {street ?? ""}
                        <br />
                        {country ?? ""} {postcode ?? ""} {place ?? ""}
                    </p>
                    <p>
                        {phone && (
                            <>
                                Telefon: {phone}
                                <br />
                            </>
                        )}
                        {fax && (
                            <>
                                Fax: {fax}
                                <br />
                            </>
                        )}
                        {email && (
                            <p>
                                E-Mail: <a href={`mailto:${email}`}>{email}</a>
                            </p>
                        )}
                        {typeof window != "undefined" ? (
                            <a href={`https://${window.location.hostname}`} target="_blank">
                                {window.location.hostname}
                            </a>
                        ) : (
                            ""
                        )}
                    </p>
                    USt ID: {taxID ?? ""}
                    <br></br>
                    {commercialRegister && `Handelsregister: ${commercialRegister}`}
                    <br></br>
                    <br></br>
                    Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:<br></br>
                    {managingDirector ?? ""}
                    <br></br>
                    <ImprintAppendum imprintAddendum={imprintAddendum} />
                </>
            );
        }

        // GBR
        return (
            <>
                <p>
                    {companyNameOverwriteImprint && companyNameOverwriteImprint.trim() != "" ? (
                        <>
                            {companyNameOverwriteImprint} <br />
                        </>
                    ) : (
                        <>
                            {managingDirector + " " + companyFormOfLawFormatted ?? ""} <br />
                        </>
                    )}
                    {street ?? ""}
                    <br />
                    {country ?? ""} {postcode ?? ""} {place ?? ""}
                    <br />
                </p>
                <p>
                    Vertretungsberechtigt: <br />
                    Vertreten durch {leadingCharacter == "Inhaber" ? "den" : lawString == "gbr" ? "die" : "den/die"}
                    {" " + leadingCharacter}
                    <br />
                    <span>{managingDirector ?? ""} </span>
                </p>
                <p>
                    {phone && (
                        <>
                            Telefon: {phone}
                            <br />
                        </>
                    )}
                    {fax && (
                        <>
                            Fax: {fax}
                            <br />
                        </>
                    )}
                    {email && (
                        <p>
                            E-Mail: <a href={`mailto:${email}`}>{email}</a>
                        </p>
                    )}
                    {typeof window != "undefined" ? (
                        <a href={`https://${window.location.hostname}`} target="_blank">
                            {window.location.hostname}
                        </a>
                    ) : (
                        ""
                    )}
                </p>
                USt ID: {taxID ?? ""}
                <br></br>
                {commercialRegister && `Handelsregister: ${commercialRegister}`}
                <br></br>
                <br></br>
                Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:<br></br>
                {managingDirector ?? ""}
                <br></br>
                <ImprintAppendum imprintAddendum={imprintAddendum} />
            </>
        );
    }

    return null;
};
