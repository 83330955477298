import React, { Fragment, FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { css } from "styled-components";
import { IContext, Context } from "../../index";
import { handleFormSubmit } from "../../utils/formSubmit";
import { FriendlyCaptcha } from "../friendly-capture";

export const ServiceForm: FunctionComponent = () => {
    const pageProps = useContext<IContext>(Context);

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false);
    const footer = pageProps?.Footer;
    const universalTexts = pageProps?.UniversalTexts;

    const onSubmit = (data): void => {
        //Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        //Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
        //Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
        //Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e
        let title: string | undefined = undefined;
        if (typeof window !== "undefined") {
            title = document.title;
        }
        const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

        handleFormSubmit({
            ...data,
            fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
            line: pageProps?.CompanyInformationPrivate?.line,
            subject: `Reparaturanfrage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
            dealerMail: pageProps?.CompanyInformationPublic?.email,
            templateId: "d-82dc22fc5dee48db9e177c0140b37441",
            // cc: [{ email: "serviceseite@gedk.de" }]
        }).then((r) => {
            reset();
            setSuccess(true);
        });
    };

    const dateOptions = [];
    const today = new Date();

    for (let i = 1; i < 9; i++) {
        const newDate = new Date();
        newDate.setDate(today.getDate() + i);
        dateOptions.push(
            <option key={i} value={newDate.toLocaleDateString()}>
                {newDate.toLocaleDateString()}
            </option>
        );
    }

    const timeOptions: React.ReactElement[] = [];
    const timeOptions2: React.ReactElement[] = [];

    for (let j = 8; j < 22; j++) {
        let value;
        let value2;

        if (j < 10) {
            value = `0${j}:00`;
            value2 = `0${j}:30`;
        } else {
            value = `${j}:00`;
            value2 = `${j}:30`;
        }

        timeOptions.push(
            <Fragment key={j}>
                <option value={value}>{value}</option>
                <option value={value2}>{value2}</option>
            </Fragment>
        );

        timeOptions2.push(
            <Fragment key={j + 999}>
                {j !== 8 && <option value={value}>{value}</option>}
                <option value={value2}>{value2}</option>
            </Fragment>
        );
    }

    const brandOptions = [];
    //   const allowedBrandNames = [];

    if (pageProps.PrimaryNavigation?.items) {
        pageProps.PrimaryNavigation?.items.map((item) => {
            if (
                `${item.label}`.toLowerCase().includes("marken") ||
                `${item.link?.[0]?.nameInNavigation}`.toLowerCase().includes("marken") ||
                `${item.link?.[0]?.__typename}`.toLowerCase().includes("PageBrand") ||
                `${item.subitems?.[0]?.__typename}`.toLowerCase().includes("PageBrand")
            ) {
                item.subitems?.map((subitem, i) => {
                    brandOptions.push(
                        <option key={subitem.id + i} value={subitem.nameInNavigation}>
                            {subitem.nameInNavigation}
                        </option>
                    );
                });
                // console.log(` item.subitems`, item.subitems);
                // item.subitems?.map((subitem) =>
                //   allowedBrandNames.push(subitem.nameInNavigation)
                // );
            }
        });
    }
    //   console.log(` allowedBrandNames`, allowedBrandNames);
    //   for (const brand of pageProps.AllBrand) {
    //   console.log(` brand`, brand.name);

    //     if (
    //       allowedBrandNames.find(
    //         (name) => `${name}`.trim().toLowerCase() === `${brand.name}`.trim().toLowerCase()
    //       )
    //     )
    //       brandOptions.push(
    //         <option key={brand.id} value={brand.name}>
    //           {brand.name}
    //         </option>
    //       );
    //   }

    const deviceTypeOptions = [];

    if (pageProps?.AllDeviceType?.length > 0) {
        let k = 0;

        for (const deviceType of pageProps.AllDeviceType) {
            deviceTypeOptions.push(
                <option key={k} value={deviceType.name}>
                    {deviceType.name}
                </option>
            );
            k++;
        }
    }

    const vorname = "*Vorname";
    const nachname = "*Nachname";
    const email = "*E-Mail";
    const adresse = "*Adresse (Straße + Nummer)";
    const ort = "*Ort";
    const plz = "*PLZ";
    const land = "Land";
    const tel = "*Telefonnummer";
    const date = "Datum";
    const uhrzeitVon = "Uhrzeit (von)";
    const uhrzeitBis = "Uhrzeit (bis)";
    const hersteller = "Hersteller";
    const geraetetyp = "Gerätetyp";
    const geraetetypnummer = "Gerätetypnummer";
    const problem = "Problembeschreibung";
    const weitereHinweise = "Weitere Hinweise";

    return (
        <div className={"contactForm"}>
            {success ? (
                <form>
                    <h1>DANKE!</h1>
                    Wir werden uns sobald wie möglich bei Ihnen melden.
                </form>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"inputsWrapper"}>
                        <div className={"twoColumnsRow"}>
                            <div>
                                <label htmlFor={"vorname"}>{vorname}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="vorname"
                                    id={"vorname"}
                                    ref={register({ required: true })}
                                />
                            </div>
                            <div>
                                <label htmlFor={"nachname"}>{nachname}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="nachname"
                                    id={"nachname"}
                                    ref={register({ required: true })}
                                />
                            </div>
                        </div>

                        <div className="twoColumnsRow">
                            <div>
                                <label htmlFor={"email"}>{email}</label>
                                <input
                                    required={true}
                                    type="email"
                                    id={"email"}
                                    name="email"
                                    ref={register({ required: true, min: 4 })}
                                />
                            </div>
                            <div>
                                <label htmlFor={"adresse"}>{adresse}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="adresse"
                                    id={"adresse"}
                                    ref={register({ required: true })}
                                />
                            </div>
                        </div>

                        <div className={"twoColumnsRow"}>
                            <div>
                                <label htmlFor={"ort"}>{ort}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="ort"
                                    id={"ort"}
                                    ref={register({ required: true })}
                                />
                            </div>
                            <div>
                                <label htmlFor={"plz"}>{plz}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="plz"
                                    id={"plz"}
                                    ref={register({ required: true })}
                                />
                            </div>
                        </div>

                        <div className="twoColumnsRow">
                            <div>
                                <label htmlFor={"land"}>{land}</label>
                                <select name="land" id="land" ref={register()}>
                                    <option value="deutschland">Deutschland</option>
                                    <option value="oesterreich">Österreich</option>
                                    <option value="schweiz">Schweiz</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"tel"}>{tel}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="tel"
                                    id={"tel"}
                                    ref={register({ required: true })}
                                />
                            </div>
                        </div>

                        <div className={"twoColumnsRow threeColumns"}>
                            <div>
                                <label htmlFor={"date"}>{date}</label>
                                <select name="date" id="date" ref={register()}>
                                    {dateOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitVon"}>{uhrzeitVon}</label>
                                <select name="uhrzeitVon" id="uhrzeitVon" ref={register()}>
                                    {timeOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"uhrzeitBis"}>{uhrzeitBis}</label>
                                <select name="uhrzeitBis" id="uhrzeitBis" ref={register()}>
                                    {timeOptions2}
                                </select>
                            </div>
                        </div>

                        <div className={"twoColumnsRow"}>
                            <div>
                                <label htmlFor={"hersteller"}>{hersteller}</label>
                                <select name="hersteller" id="hersteller" ref={register()}>
                                    {brandOptions}
                                </select>
                            </div>
                            <div>
                                <label htmlFor={"geraetetyp"}>{geraetetyp}</label>
                                <select name="geraetetyp" id="geraetetyp" ref={register()}>
                                    {deviceTypeOptions}
                                </select>
                            </div>
                        </div>

                        <div className={"twoColumnsRow"}>
                            <div>
                                <label htmlFor={"geraetetypnummer"}>{geraetetypnummer}</label>
                                <input type="text" name="geraetetypnummer" id={"geraetetypnummer"} ref={register()} />
                            </div>
                        </div>

                        <label htmlFor={"problem"}>{problem}</label>
                        <textarea rows={8} name="problem" id={"problem"} ref={register()} />

                        <label htmlFor={"weitereHinweise"}>{weitereHinweise}</label>
                        <textarea rows={8} name="weitereHinweise" id={"weitereHinweise"} ref={register()} />
                    </div>
                    <div className={"twoColumnsRow submitWrapper"}>
                        <FriendlyCaptcha />
                    </div>
                    <div className={"twoColumnsRow submitWrapper"}>
                        <div>
                            <input
                                css={css`
                                    position: absolute;
                                    opacity: 0;
                                    pointer-events: none;
                                `}
                                type="privacy"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="privacy"
                            />
                            <input
                                required={true}
                                type="checkbox"
                                placeholder="Ich akzeptiere die Datenschutzerklärung"
                                name="checkbox"
                                id={"checkbox"}
                                ref={register({ required: true })}
                            />
                            <label htmlFor={"checkbox"} className="datenschutzAkzeptieren">
                                Ich akzeptiere die <a href={pageProps?.PageImprint?.slug}> Datenschutzerklärung</a>
                            </label>
                        </div>

                        <button className="submitbutton" type="submit">
                            {universalTexts?.Submit || "Absenden"}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};
